import { render, staticRenderFns } from "./CommunityQuestionList.vue?vue&type=template&id=a46b00f4&scoped=true&"
import script from "./CommunityQuestionList.vue?vue&type=script&lang=js&"
export * from "./CommunityQuestionList.vue?vue&type=script&lang=js&"
import style0 from "./CommunityQuestionList.vue?vue&type=style&index=0&id=a46b00f4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a46b00f4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommunityPinned: require('/mnt/volume_berlin_01/frontends/mobile-community/components/CommunityPinned.vue').default,CommunityQuestionMember: require('/mnt/volume_berlin_01/frontends/mobile-community/components/CommunityQuestionMember.vue').default})
